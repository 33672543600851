import {API_URL} from "../config";
import {getResponseJson} from "./common";

export class RegistrationError extends Error {
}

export async function getMe(token) {
    const response = await fetch(`${API_URL}/api/private/v1/users/me/`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            "Authorization": `Bearer ${token}`,
        },
    });
    return getResponseJson(response);
}

export async function patchMe(token, user) {
    const response = await fetch(`${API_URL}/api/private/v1/users/me/`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(user)
    });
    return getResponseJson(response);
}

export async function postUsers(username, email, password) {
    const response = await fetch(`${API_URL}/api/private/v1/users/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify({username: username, email: email, password: password})
    });
    const responseJson = await response.json();

    if (response.status === 200) {
        return responseJson
    } else if (responseJson.detail.includes("username")) {
        throw new RegistrationError(responseJson.detail);
    } else if (responseJson.detail.includes("email")) {
        throw new RegistrationError(responseJson.detail);
    } else if (responseJson.detail.includes("password")) {
        throw new RegistrationError(responseJson.detail);
    } else {
        throw new Error(`Resonse code: ${response.status}\n` + JSON.stringify(responseJson));
    }
}