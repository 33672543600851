import React, {lazy, Suspense, useState} from 'react';
import {
    AuthContext,
    checkAuthContextAndGetActual,
    getAuthContextUser,
    OnlyAuthenticatedRoute,
    OnlyUnauthenticatedRoute
} from './auth';
import {VersionComponent} from '../common/VersionComponent';
import {BrowserRouter as Router, Redirect, Switch,} from "react-router-dom";
import {Loader} from "./Loader";
import {cssTransition, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {useTranslation} from "react-i18next";

const MedicalApp = lazy(() => import('./app/index'));
const SignIn = lazy(() => import('./sign-in/index'));
const SignUp = lazy(() => import('./sign-up/index'));


function ChangeLanguage(props) {
    const {t, i18n} = useTranslation();
    if (props.authContext.settings
        && "lang" in props.authContext.settings
        && i18n.resolvedLanguage !== props.authContext.settings["lang"]) {
        i18n.changeLanguage(props.authContext.settings["lang"]);
    }
    return <></>;
}

export function MainComponent(props) {
    const authContextInit = getAuthContextUser();
    const [authContext, setAuthContext] = useState(authContextInit);
    checkAuthContextAndGetActual(authContextInit).then((authContext) => setAuthContext(authContext));

    const bounce = cssTransition({
        enter: "slide-in-right",
        exit: "slide-out-right"
    });
    return (
        <AuthContext.Provider value={{user: authContext ? authContext : authContextInit, setUser: setAuthContext}}>
            <Router>
                <Suspense fallback={<Loader/>}>
                    <ChangeLanguage authContext={authContext ? authContext : authContextInit}/>
                    <Switch>
                        <OnlyUnauthenticatedRoute path="/sign-in" component={SignIn}/>
                        <OnlyUnauthenticatedRoute path="/sign-up" component={SignUp}/>
                        <OnlyAuthenticatedRoute path="/app" component={MedicalApp}/>
                        <Redirect to="/sign-in"/>
                    </Switch>
                    <VersionComponent/>
                </Suspense>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={bounce}
                />
            </Router>
        </AuthContext.Provider>
    );
}