import React, {useContext} from "react";
import {Redirect, Route} from "react-router-dom";
import {getMe} from "../api/private-v1-users";
import {errorHandler} from "../common/errorHandler";

const unauthenticatedUser = {
    username: null,
    token: null,
    is_developer: false,
    is_staff: false,
    settings: {},
    available_pipelines: []
}

export const AuthContext = React.createContext({
    user: unauthenticatedUser,
    setUser: (newUser) => {
    },
});

export function getAuthContextUser() {
    let user = loadAuthContext();
    if (user === null) {
        user = unauthenticatedUser;
    }
    return user
}

export async function checkAuthContextAndGetActual(authContext) {
    if (!authContext.token) {
        return unauthenticatedUser;
    }
    await getMe(authContext.token)
        .then(({username, is_developer, is_staff, settings, available_pipelines}) => {
            authContext.username = username;
            authContext.is_developer = is_developer;
            authContext.is_staff = is_staff;
            authContext.settings = settings;
            authContext.available_pipelines = available_pipelines;
            localStorage.setItem("authContext", JSON.stringify(authContext));
            return authContext;
        })
        .catch((error) => {
            if (error.toString() === 'Error: Response code: 401\n{"detail":"Invalid token."}') {
                clearAuthContextSave();
            }
            errorHandler(error);
            return unauthenticatedUser;
        })
}

export async function saveAuthContext(token) {
    const {username, is_developer} = await getMe(token);
    const newAuthContext = Object.assign({}, unauthenticatedUser);
    newAuthContext.username = username;
    newAuthContext.token = token;
    newAuthContext.is_developer = is_developer;
    localStorage.setItem("authContext", JSON.stringify(newAuthContext));
    return newAuthContext;
}

export function loadAuthContext() {
    return JSON.parse(localStorage.getItem("authContext"))
}

export function clearAuthContextSave() {
    localStorage.removeItem("authContext")
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function OnlyAuthenticatedRoute({component: Component, ...rest}) {
    const auth = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={props =>
                auth.user.token ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/sign-in"/>
                )
            }
        />
    );
}

export function OnlyUnauthenticatedRoute({component: Component, ...rest}) {
    const auth = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={props =>
                !auth.user.token ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/app"/>
                )
            }
        />
    );
}

