import {useEffect, useState} from "react";
import {getVersion} from "../api/private-v1-version";

export function VersionComponent() {
    const [version, setVersion] = useState('');

    const getData = async () => {
        const res = await getVersion();
        setVersion(res.version);
    };
    useEffect(() => {
        getData();
    }, []);

    if (!version) {
        return <></>;
    }

    return (
        <div className="version">
            <div dangerouslySetInnerHTML={{__html: version.replaceAll("\n", "<br>")}}/>
        </div>
    );
}