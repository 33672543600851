import {deepEqual} from "./deepEqualFunction";
import {toast} from 'react-toastify';

export function errorHandler(error) {
    if (deepEqual(error, {isCanceled: true})) {
        return;
    }
    let errorMessage = error.toString();
    if (error.message === "Failed to fetch") {
        errorMessage = "Error: Connection error"
    }
    toast.error(errorMessage);
    console.error(error)
}