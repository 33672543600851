export function Loader() {
    return (

        <div className="vertical-layout boxed" id="start-loader">
            <div className="app-loader main-loader">
                <div className="loader-box">
                    <div className="bounceball"></div>
                    <div className="text">Oxygen tech.<span>online</span></div>
                </div>
            </div>
        </div>
    );
}