export async function getResponseJson(response) {
    let responseJson;
    try {
        responseJson = await response.json();
    } catch (e) {
        throw new Error(`Error\nResponse code: ${response.status}\n`);
    }

    if (parseInt(response.status / 100) === 2) {
        return responseJson;
    } else {
        throw new Error(`Response code: ${response.status}\n` + JSON.stringify(responseJson));
    }
}